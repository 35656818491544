import './logistic_flow.scss';

import { useState } from 'react';

import { ReactComponent as ChevronDownIcon } from '../../icons/chevron_down.svg';
import MilestoneDetails from '../milestoneDetails/MilestoneDetails';

function LogisticFlow(props) {
  const [logisticFlowDetailsIsOpen, setLogisticFlowDetailsIsOpen] = useState(false);

  const getLogisticFlowStatusStyle = () => {
    return (
      'smc-TrackingDeliveryLogisticFlow-statusTxt--' +
      (props.order.LogisticFlowStatus.Status === 0 ? 'success' : 'error')
    );
  };

  const toggleLogisticFlowDetail = () => {
    logisticFlowDetailsIsOpen ? setLogisticFlowDetailsIsOpen(false) : setLogisticFlowDetailsIsOpen(true);
  };

  return (
    <div className="smc-TrackingDeliveryLogisticFlow">
      <div className="smc-TrackingDeliveryLogisticFlow-wrapper">
        <div className="smc-TrackingDeliveryLogisticFlow-status">
          <span className="smc-TrackingDeliveryLogisticFlow-statusTxt">{props.order.LogisticFlowName}</span>
          <span className={`smc-TrackingDeliveryLogisticFlow-statusTxt ${getLogisticFlowStatusStyle()}`}>
            {props.order.LogisticFlowStatus.Name}
          </span>
        </div>
        <button
          type="button"
          className={`smc-TrackingDeliveryLogisticFlow-openDetails${
            logisticFlowDetailsIsOpen ? ' smc-TrackingDeliveryLogisticFlow-openDetails--opened' : ''
          }`}
          onClick={toggleLogisticFlowDetail}
        >
          <span className="smc-TrackingDeliveryLogisticFlow-openDetailsTxt">
            {logisticFlowDetailsIsOpen ? 'Voir moins' : 'Voir le détail des jalons'}
          </span>
          <ChevronDownIcon className="smc-TrackingDeliveryLogisticFlow-openDetailIcon" />
        </button>
      </div>
      <MilestoneDetails
        milestoneDetailsOpened={logisticFlowDetailsIsOpen}
        milestones={props.order.Milestones}
        reference={props.order.Reference}
      />
    </div>
  );
}

export default LogisticFlow;
