import './order_tracking.scss';

import Card from '../../ui/Card/Card';
import Cardtitle from '../../ui/cardTitle/CardTitle';
import KeyValueInfo from '../../ui/KeyValueInfo/KeyValueInfo';
import TimeLine from '../timeLine/TimeLine';

function OrderTracking(props) {
  return (
    <div className="smc-TrackingDeliveryOrderTracking">
      <Card>
        {props.statusOrders.map((statusOrder, index) => {
          return (
            <div key={index} className="smc-TrackingDeliveryOrderTracking-wrapper">
              <Cardtitle>Type de commande : {statusOrder.DeliveryType}</Cardtitle>
              <KeyValueInfo keyInfo="Numéro de commande" valueInfo={props.orderNumber} />
              <TimeLine statusOrder={statusOrder.TimeLine} />
            </div>
          );
        })}
      </Card>
    </div>
  );
}

export default OrderTracking;
