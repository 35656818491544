import moment from 'moment';
import { useEffect, useState } from 'react';

import ApiError from './components/apiError/ApiError';
import CustomerInfos from './components/customerInfos/CustomerInfos';
import OrderStatus from './components/orderStatus/OrderStatus';
import OrderTracking from './components/orderTracking/OrderTracking';
import Layout from './layout/Layout';
import Spinner from './ui/spinner/Spinner';

function App() {
  const baseUrl = `${process.env.REACT_APP_TRACKING_API_URL}/v1/orders/`;
  const [loadedOrderStatus, setLoadedOrderStatus] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [apiHttpCode, setApiHttpCode] = useState(null);
  const [urlParams, setUrlParams] = useState('');
  const [isOnIframe, setIsOnIFrame] = useState(false);
  const [isWebOrder, setIsWebOrder] = useState(false);
  const [isSailsIdFormatOnError, setIsSailsIdFormatOnError] = useState(false);

  // Set ContextOrder state to 0 by defaultS
  const [contextLevel, setContextLevel] = useState(0);

  useEffect(() => {
    const queryString = window.location.search;
    const searchUrlParams = new URLSearchParams(queryString);
    const body = document.body;
    const html = document.documentElement;

    const getDocumentHeight = () => {
      return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    };

    const matchWebOrder = (storeNumber) => {
      if (storeNumber.match('50')) {
        setIsWebOrder(true);
      }
    };

    const smcTrackingHearder = new Headers({
      'x-api-key': process.env.REACT_APP_TRACKING_API_KEY,
    });
    let queryStringParams = '';

    if (searchUrlParams.has('SalesId')) {
      const orderId = searchUrlParams.get('SalesId');

      if (orderId.length !== 16) {
        setIsSailsIdFormatOnError(true);
        setIsLoading(false);
      }
      const [orderDateNotFormated, storeOrderNumber] = orderId.split('C');

      const orderDateFormated = moment(orderDateNotFormated).format('YYYY-MM-DD');
      const storeNumber = storeOrderNumber.substring(0, 3);
      const orderNumber = storeOrderNumber.substring(3);

      matchWebOrder(storeNumber);

      queryStringParams = `${orderNumber}/?OrderDate=${orderDateFormated}&StoreNumber=${storeNumber}&OrderNumber=${orderNumber}`;
    } else {
      if (searchUrlParams.has('OrderNumber')) queryStringParams += `${searchUrlParams.get('OrderNumber')}/`;

      if (searchUrlParams.has('StoreNumber')) {
        queryStringParams += `?StoreNumber=${searchUrlParams.get('StoreNumber')}`;
        matchWebOrder(searchUrlParams.get('StoreNumber'));
      }

      if (searchUrlParams.has('OrderDate')) queryStringParams += `&OrderDate=${searchUrlParams.get('OrderDate')}`;
    }

    // Token parsing
    if (searchUrlParams.has('Token')) {
      queryStringParams += `&Token=${searchUrlParams.get('Token')}`;
    }

    if (searchUrlParams.has('ContextLevel')) {
      setContextLevel(parseInt(searchUrlParams.get('ContextLevel'), 10));
      queryStringParams += `&ContextLevel=${searchUrlParams.get('ContextLevel')}`;
    }

    if (searchUrlParams.has('iframe')) {
      setIsOnIFrame(searchUrlParams.get('iframe') === 'true');
    }

    setUrlParams(queryStringParams);

    if (urlParams && !isSailsIdFormatOnError) {
      fetch(`${baseUrl}${urlParams}`, {
        method: 'GET',
        headers: smcTrackingHearder,
        mode: 'cors',
      })
        .then((response) => {
          setApiHttpCode(response.status);

          if (!response.ok) {
            setIsLoading(false);
            throw new Error(response.statusText);
          }

          return response.json();
        })
        .then((data) => {
          setLoadedOrderStatus(data);
          setIsLoading(false);

          if (window && window.parent) {
            window.parent.postMessage({ windowHeight: getDocumentHeight() + 'px' }, window.location.origin);
          }
        });
    }
  }, [urlParams, baseUrl, isSailsIdFormatOnError]);

  const { OrderNumber } = loadedOrderStatus;

  useEffect(() => {
    let documentTitle = 'Suivi de commande';

    if (OrderNumber) {
      documentTitle += ` N°${OrderNumber}`;
    }
    document.title = documentTitle;
  }, [OrderNumber]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isSailsIdFormatOnError) {
    return <ApiError textMessage="Veuillez saisir un numéro de commande valide" />;
  }

  if (apiHttpCode !== 200) {
    return <ApiError statusCode={apiHttpCode} isWebOrder={isWebOrder} />;
  }

  return (
    <Layout isOnIframe={isOnIframe}>
      <h1 className="smc-TrackingDelivery-title">Suivi de commande n° {loadedOrderStatus.OrderNumber}</h1>
      {loadedOrderStatus.Client && (
        <CustomerInfos
          customerName={loadedOrderStatus.Client.Name}
          customerAdress={loadedOrderStatus.Client.Adress}
          customerEmail={loadedOrderStatus.Client.Email}
          customerPhone={loadedOrderStatus.Client.Phone}
          customerMobile={loadedOrderStatus.Client.Mobile}
          customerAccount={loadedOrderStatus.Client.ClientAccount}
          orderStatus={loadedOrderStatus.StatusOrder}
          orderDeliveryType={loadedOrderStatus.DeliveryType}
          orderIsLaying={loadedOrderStatus.Laying}
          orderReceptionDate={loadedOrderStatus.ConfirmedReceptionDate}
          receptionDateKey={loadedOrderStatus.ChampsDate}
          contextLevel={contextLevel}
        />
      )}
      {loadedOrderStatus.MilestoneStatusOrder && loadedOrderStatus.OrderNumber && (
        <OrderTracking
          statusOrders={loadedOrderStatus.MilestoneStatusOrder}
          orderNumber={loadedOrderStatus.OrderNumber}
        />
      )}
      {loadedOrderStatus.LineOrder && (
        <OrderStatus lineOrder={loadedOrderStatus.LineOrder} contextLevel={contextLevel} />
      )}
    </Layout>
  );
}

export default App;
