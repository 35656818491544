import './key_value_info.scss';

function KeyValueInfo(props) {
  return (
    <div className={'smc-TrackingDeliverykeyValueInfo' + (props.className ? ' ' + props.className : '')}>
      <span className="smc-TrackingDeliverykeyValueInfo-key">{props.keyInfo}</span>
      <span className="smc-TrackingDeliverykeyValueInfo-value">{props.valueInfo}</span>
    </div>
  );
}

export default KeyValueInfo;
