import './line_order_table.scss';
function LineOrderTable(props) {
  return (
    <div className="smc-TrackingDeliveryLineOrderTable">
      <table className="smc-TrackingDeliveryLineOrderTable-table">
        <thead>
          <tr className="smc-TrackingDeliveryLineOrderTable-row">
            <th className="smc-TrackingDeliveryLineOrderTable-titleCell">N° ligne</th>
            <th className="smc-TrackingDeliveryLineOrderTable-titleCell">Flux</th>
            <th className="smc-TrackingDeliveryLineOrderTable-titleCell">Référence</th>
            <th className="smc-TrackingDeliveryLineOrderTable-titleCell smc-TrackingDeliveryLineOrderTable-titleCell--bigger">
              Nom du produit
            </th>
            <th className="smc-TrackingDeliveryLineOrderTable-titleCell">Larg.</th>
            <th className="smc-TrackingDeliveryLineOrderTable-titleCell">Long.</th>
            <th className="smc-TrackingDeliveryLineOrderTable-titleCell">Quantité</th>
            <th className="smc-TrackingDeliveryLineOrderTable-titleCell">Unité</th>
            <th className="smc-TrackingDeliveryLineOrderTable-titleCell">Montant</th>
          </tr>
        </thead>
        <tbody>
          <tr className="smc-TrackingDeliveryLineOrderTable-row">
            <td className="smc-TrackingDeliveryLineOrderTable-cell smc-TrackingDeliveryLineOrderTable-cell--bolder">
              {props.order.LineNumber ? props.order.LineNumber : '-'}
            </td>
            <td className="smc-TrackingDeliveryLineOrderTable-cell">{props.order.Flux ? props.order.Flux : '-'}</td>
            <td className="smc-TrackingDeliveryLineOrderTable-cell">{props.order.QCT ? props.order.QCT : '-'}</td>
            <td className="smc-TrackingDeliveryLineOrderTable-cell">
              {props.order.ProductName ? props.order.ProductName : '-'}
            </td>
            <td className="smc-TrackingDeliveryLineOrderTable-cell">{props.order.Width ? props.order.Width : '-'}</td>
            <td className="smc-TrackingDeliveryLineOrderTable-cell">{props.order.Length ? props.order.Length : '-'}</td>
            <td className="smc-TrackingDeliveryLineOrderTable-cell">
              {props.order.Qantity ? props.order.Qantity : '-'}
            </td>
            <td className="smc-TrackingDeliveryLineOrderTable-cell">{props.order.Unit ? props.order.Unit : '-'}</td>
            <td className="smc-TrackingDeliveryLineOrderTable-cell">
              {props.order.Amount ? `${props.order.Amount} €` : '-'}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default LineOrderTable;
