import './time_line.scss';

import moment from 'moment';
import { AiOutlineCheck } from 'react-icons/ai';

function TimeLine(props) {
  const getTimelineIsOverflow = () => {
    if (props.statusOrder && Array.isArray(props.statusOrder) && props.statusOrder.length > 8) {
      return true;
    }
    return false;
  };

  const checkIfDateIsDisplayed = (index) => {
    if (props.statusOrder[index].Status === 1) {
      return true;
    }

    return props.statusOrder[index].hasDateDisplayed;
  };
  const checkIfStepIsCompleted = (status) => status === 1;

  function getSteps() {
    return props.statusOrder.map((order, index) => {
      const stepClasses = ['smc-TrackingDeliveryTimeLine-step'];
      if (order.Status === 1) {
        stepClasses.push('smc-TrackingDeliveryTimeLine-step--active');
      }

      return (
        <div key={index} className={stepClasses.join(' ')}>
          <div className="smc-TrackingDeliveryTimeLine-bubble">
            {checkIfStepIsCompleted(order.Status) ? <AiOutlineCheck /> : <span>{index + 1}</span>}
            <p className="smc-TrackingDeliveryTimeLine-bubbleTxt">
              <span>{order.Value}</span>
              <span>{checkIfDateIsDisplayed(index) ? moment(order.Date, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}</span>
            </p>
          </div>
        </div>
      );
    });
  }

  return (
    <div
      className={
        'smc-TrackingDeliveryTimeLine' + (getTimelineIsOverflow() ? ' smc-TrackingDeliveryTimeLine--overflow' : '')
      }
    >
      <div
        className={
          'smc-TrackingDeliveryTimeLine-steper' +
          (getTimelineIsOverflow() ? ' smc-TrackingDeliveryTimeLine-steper--inlineFlex' : '')
        }
      >
        {getSteps()}
      </div>
    </div>
  );
}

export default TimeLine;
