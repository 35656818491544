import './milestone_card.scss';

import moment from 'moment';

import { ReactComponent as MilestoneErrorIcon } from '../../icons/milestone_error.svg';
import { ReactComponent as MilestoneSuccessIcon } from '../../icons/milestone_success.svg';
import { ReactComponent as MilestoneWaitingIcon } from '../../icons/milestone_waiting.svg';

function MilestoneCard(props) {
  const getMilestoneStatus = (status) => {
    let displaySatus;

    switch (status) {
      case 0:
        displaySatus = (
          <div className="smc-TrackingDeliveryMilestoneCard-status smc-TrackingDeliveryMilestoneCard-status--waiting">
            <MilestoneWaitingIcon className="smc-TrackingDeliveryMilestoneCard-statusIcon" />
            <span className="smc-TrackingDeliveryMilestoneCard-statusTxt">En attente</span>
          </div>
        );
        break;
      case 1:
        displaySatus = (
          <div className="smc-TrackingDeliveryMilestoneCard-status smc-TrackingDeliveryMilestoneCard-status--inprogress">
            <MilestoneWaitingIcon className="smc-TrackingDeliveryMilestoneCard-statusIcon" />
            <span className="smc-TrackingDeliveryMilestoneCard-statusTxt">En cours</span>
          </div>
        );
        break;
      case 2:
        displaySatus = (
          <div className="smc-TrackingDeliveryMilestoneCard-status smc-TrackingDeliveryMilestoneCard-status--success">
            <MilestoneSuccessIcon className="smc-TrackingDeliveryMilestoneCard-statusIcon" />
            <span className="smc-TrackingDeliveryMilestoneCard-statusTxt">Jalon validé</span>
          </div>
        );
        break;
      case 3:
        displaySatus = (
          <div className="smc-TrackingDeliveryMilestoneCard-status smc-TrackingDeliveryMilestoneCard-status--error">
            <MilestoneErrorIcon className="smc-TrackingDeliveryMilestoneCard-statusIcon" />
            <span className="smc-TrackingDeliveryMilestoneCard-statusTxt">Jalon retardé</span>
          </div>
        );
        break;
      default:
        displaySatus = '';
    }

    return displaySatus;
  };
  return (
    <div className="smc-TrackingDeliveryMilestoneCard">
      <div className="smc-TrackingDeliveryMilestoneCard-status-top">
        <div className="smc-TrackingDeliveryMilestoneCard-status">{getMilestoneStatus(props.milestone.Status)}</div>
        <div className="smc-TrackingDeliveryMilestoneCard-title">{props.milestone.NameDisplay}</div>
      </div>
      <div className="smc-TrackingDeliveryMilestoneCard-bottom">
        {props.milestone.RequestedDate && (
          <div className="smc-TrackingDeliveryMilestoneCard-date">
            <span className="smc-TrackingDeliveryMilestoneCard-dateTitle">Date initiale</span>
            <span className="smc-TrackingDeliveryMilestoneCard-dateValue">
              {moment(props.milestone.RequestedDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
            </span>
          </div>
        )}

        {props.milestone.ConfirmedDate && (
          <div className="smc-TrackingDeliveryMilestoneCard-date">
            <span className="smc-TrackingDeliveryMilestoneCard-dateTitle">Date modifiée</span>
            <span className="smc-TrackingDeliveryMilestoneCard-dateValue">
              {moment(props.milestone.ConfirmedDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
            </span>
          </div>
        )}
        {props.milestone.RealisedDate && (
          <div className="smc-TrackingDeliveryMilestoneCard-date">
            <span className="smc-TrackingDeliveryMilestoneCard-dateTitle">Date réelle</span>
            <span className="smc-TrackingDeliveryMilestoneCard-dateValue">
              {moment(props.milestone.RealisedDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default MilestoneCard;
