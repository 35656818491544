import './customer_infos.scss';

import Card from '../../ui/Card/Card';
import KeyValueInfo from '../../ui/KeyValueInfo/KeyValueInfo';
import SubTitle from '../../ui/subTitle/SubTitle';

function CustomerInfos(props) {
  if (props.contextLevel < 1) {
    return null;
  }

  const formatPhone = (str) =>
    str
      .trim()
      .replace(/\s/g, '')
      .match(/.{1,2}/g)
      .join(' ');

  return (
    <div className="smc-TrackingDeliveryCustomerInfos">
      <SubTitle>Informations client</SubTitle>
      <Card>
        <div className="smc-TrackingDeliveryCustomerInfos-wrapper">
          <div className="smc-TrackingDeliveryCustomerInfos-col">
            <KeyValueInfo keyInfo="Nom" valueInfo={props.customerName} />
            <KeyValueInfo
              keyInfo="Adresse"
              valueInfo={props.customerAdress}
              className="smc-TrackingDeliverykeyValueInfo--adress"
            />
            {props.customerPhone && (
              <KeyValueInfo keyInfo="Téléphone fixe" valueInfo={formatPhone(props.customerPhone)} />
            )}
            {props.customerMobile && (
              <KeyValueInfo keyInfo="Téléphone portable" valueInfo={formatPhone(props.customerMobile)} />
            )}
            <KeyValueInfo keyInfo="Email" valueInfo={props.customerEmail} />
          </div>
          <div className="smc-TrackingDeliveryCustomerInfos-col">
            <KeyValueInfo keyInfo="Statut" valueInfo={props.orderStatus} />
            <KeyValueInfo keyInfo={props.receptionDateKey} valueInfo={props.orderReceptionDate} />
            <KeyValueInfo keyInfo="Type de livraison" valueInfo={props.orderDeliveryType} />
            <KeyValueInfo keyInfo="Prestation de pose" valueInfo={props.orderIsLaying ? 'Oui' : 'Non'} />
            <KeyValueInfo keyInfo="Identifiant client" valueInfo={props.customerAccount} />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default CustomerInfos;
