import './header.scss';

import { ReactComponent as CrossIcon } from '../../icons/cross.svg';
import logo from '../../smc-logo.png';

function Header() {
  const onClose = () => {
    window.opener = null;
    window.open('', '_self', '');
    window.close();
  };
  return (
    <header className="smc-TrackingDeliveryHeader">
      <img src={logo} alt="logo" className="smc-TrackingDeliveryHeader-logo" />
      <div className="smc-TrackingDeliveryHeader-closeTabBtn" onClick={onClose}>
        <span className="smc-TrackingDeliveryHeader-closeTabBtnTxt">Fermer l&apos;onglet</span>
        <CrossIcon />
      </div>
    </header>
  );
}

export default Header;
