import './card.scss';

function Card(props) {
  return (
    <div className={`smc-TrackingDelivery-card${props.noPadding ? ' smc-TrackingDelivery-card--noPadding' : ''}`}>
      {props.children}
    </div>
  );
}

export default Card;
