import './milestone_details.scss';

import KeyValueInfo from '../../ui/KeyValueInfo/KeyValueInfo';
import MilestoneCard from '../milestoneCard/MilestoneCard';

function MilestoneDetails(props) {
  const getMilestoneDetailsOpenedCssClass = () => {
    return props.milestoneDetailsOpened ? ' smc-TrackingDeliveryMilestoneDetails--opened' : '';
  };
  return (
    <div className={`smc-TrackingDeliveryMilestoneDetails${getMilestoneDetailsOpenedCssClass()}`}>
      {props.reference && (
        <div className="smc-TrackingDeliveryMilestoneDetails-wrapper">
          <KeyValueInfo keyInfo="Référence" valueInfo={props.reference} />
        </div>
      )}
      <div className="smc-TrackingDeliveryMilestoneDetails-milestoneGrid">
        {props.milestones.map((milestone, indexMilestone) => {
          return <MilestoneCard key={indexMilestone} milestone={milestone} />;
        })}
      </div>
    </div>
  );
}

export default MilestoneDetails;
