import './spinner.scss';

function Spinner() {
  return (
    <div className="smc-TrackingDeliverySpinner">
      <div className="smc-TrackingDeliverySpinner-wrapper">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    </div>
  );
}

export default Spinner;
