import './api_error.scss';

const getMessageFromHttpCode = (httpCode, isWebOrder) => {
  let msg = '';

  if (httpCode === 401) {
    msg = "Vous n'êtes pas autorisé à voir cette page";
  } else if (httpCode === 404) {
    if (isWebOrder) {
      msg =
        "Pour cette commande votre suivi n'est pas disponible, veuillez contacter le service client à l'adresse src@saint-maclou.com pour plus \nd'informations.";
    } else {
      msg =
        "Aucune information n'a été trouvée pour cette commande ! \nveuillez vérifier les informations saisies ou contacter votre magasin.";
    }
  } else {
    msg = "Une erreur s'est produite, veuillez réessayer ultérieurement";
  }

  return msg;
};

function ApiError(props) {
  return (
    <div className="smc-TrackingDeliveryOrderApiError">
      <div className="smc-TrackingDeliveryOrderApiError-txt">
        {props.textMessage ? props.textMessage : getMessageFromHttpCode(props.statusCode, props.isWebOrder)}
      </div>
    </div>
  );
}

export default ApiError;
