import './order_status.scss';

import Card from '../../ui/Card/Card';
import SubTitle from '../../ui/subTitle/SubTitle';
import LineOrderTable from '../lineOrderTable/LineOrderTable';
import LogisticFlow from '../logisticFlow/LogisticFlow';

function OrderStatus(props) {
  if (props.contextLevel < 1) {
    return null;
  }
  return (
    <div className="smc-TrackingDeliveryOrderStatus">
      <SubTitle>Détails de la commande</SubTitle>
      <Card noPadding="true">
        {props.lineOrder.map((order, orderIndex) => {
          return (
            <div key={orderIndex} className="smc-TrackingDeliveryOrderStatus-item">
              <LineOrderTable order={order} />
              <LogisticFlow order={order} />
            </div>
          );
        })}
      </Card>
    </div>
  );
}

export default OrderStatus;
