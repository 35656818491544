import './layout.scss';

import Header from './Header/Header';

function Layout(props) {
  const getDeliveryContainerClass = () => {
    const deliveryContainerClasses = ['smc-TrackingDelivery-container'];
    if (props.isOnIframe) {
      deliveryContainerClasses.push('smc-TrackingDelivery-container--iframe');
    }
    return deliveryContainerClasses.join(' ');
  };
  return (
    <div className="smc-TrackingDelivery">
      {!props.isOnIframe && <Header />}
      <div className={getDeliveryContainerClass()}>{props.children}</div>
    </div>
  );
}

export default Layout;
